import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useLanguage from '../location';

Faqs.propTypes = {
    data: PropTypes.array
};

function Faqs({ data }) {
    const language = useLanguage();

    const [dataBlock] = useState({
        uz:{
            
            subheading: 'Tez-tez so‘raladigan savollar',
            heading: 'Tez-tez so‘raladigan savollar',
            desc1: 'Quyida foydalanuvchilarimiz tomonidan tez-tez beriladigan savollar va ularning javoblari keltirilgan.',
            desc2: 'Iltimos, murojaat qilishdan oldin ushbu savollarga javoblarni tekshirib ko‘ring.'
            },
            ru: {
                subheading: 'Часто задаваемые вопросы',
                heading: 'Часто задаваемые вопросы',
                desc1: 'Ниже приведены часто задаваемые вопросы от наших пользователей и их ответы.',
                desc2: 'Пожалуйста, проверьте ответы на эти вопросы перед тем, как обратиться к нам.'
              }
    });

    const [dataTab] = useState([
        { id: 1, title: 'Xavfsizlik' },
        { id: 2, title: 'Yuklab olish' },
        { id: 3, title: 'Texnik yordam' },
        { id: 4, title: 'Boshqa' }
    ]);

    return (
        <section className="faq">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center" data-aos="fade-down" data-aos-duration="3000">
                            <h6 className="sub-heading"><span>{dataBlock[language].subheading}</span></h6>
                            <h3 className="heading">{dataBlock[language].heading}</h3>
                            <p className="mb-17">{dataBlock[language].desc1}</p>
                            <p>{dataBlock[language].desc2}</p>
                        </div>

                        <div className="faq__main flat-tabs">
                            <Tabs>
                                <TabList className='menu-tab'>
                                    {
                                        data.map((idx, index) => (
                                           

                                                <div key={index} className="content-inner">
                                                    <div className="flat-accordion row">
                                                        <div className="col-md-6 col-sm-12">
                                                            {(language === 'uz' ? idx.uz : idx.ru).slice(0, 4).map((item, indx) => (
                                                                <Accordion show={item.show} key={indx} title={item.title} className="flat-toggle h6">
                                                                    <p className="toggle-content">{item.text}</p>
                                                                </Accordion>
                                                            ))}

                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            {(language === 'uz' ? idx.uz : idx.ru).slice(4, 8).map((item, indx) => (
                                                                <Accordion show={item.show} key={indx} title={item.title} className="flat-toggle h6">
                                                                    <p className="toggle-content">{item.text}</p>
                                                                </Accordion>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                           ))
                                    }

                                </TabList>
                              


                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faqs;
