const menus = [
    {
        uz:[ {
        id: 1,
        name: 'Bosh sahifa',
        links: '/',
    },
   
    {
        id: 4,
        name: 'Ilovalar',
        links: '/apps',
        
    },
    {
        id: 5,
        name: 'Izohlar',
        links: '/',
        
    },
    {
        id: 7,
        name: 'Bog‘lanish',
        links: '/contact',
    }
],
ru: [ 
    {
        id: 1,
        name: 'Главная Страница',
        links: '/',
    },
   
    {
        id: 4,
        name: 'Приложении',
        links: '/apps',
        
    },
    {
        id: 5,
        name: 'Комменты',
        links: '/',
        
    },
    {
        id: 7,
        name: 'Контакты',
        links: '/contact',
    }
]
    }
   
    
]

export default menus;