import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useLanguage from '../location';
import Button from '../button/Button';

import img from '../../assets/images/layouts/avt-01.png'
const apiUrl = process.env.REACT_APP_API_URL;

Project3.propTypes = {
    data: PropTypes.array
};

function Project3(props) {
    const language = useLanguage();
    // State to store the fetched data
  const [appsData, setAppsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
// Function to fetch data from your API endpoint
const fetchData = async () => {
    try {
      // Fetching data from the backend
      const response = await fetch('https://ntbackend.uz/json/apps');

      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      // Parse the response JSON data
      const appsData = await response.json();

      // Set the fetched data into the state
      setAppsData(appsData);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occur during the fetch
      setError(error.message);
      setLoading(false);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Loading, error, and data display
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


    const {data} = props
   
    
    return (
        <section className="nft">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="filter">
                                <form style={{width:"100%"}} action="#" className="widget-search">
                                    <input type="text" placeholder={language === 'ru' ? "Искатьт Приложению" : "Ilovanni izlash"} required="" />
                                    <Link to='#' className="btn-search">
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9.7659" cy="9.76639" r="8.98856" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                            <path d="M16.0176 16.4849L19.5416 19.9997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </Link>
                                </form>
                               
                                
                              
                            </div>
                        </div>

                        {
                            appsData.map(idx => (
                                <div key={idx._id} className="col-xl-3 col-md-6">
                                    <div className="nft-item">                               
                                        <div className="card-media">
                                            <Link to={`/app/info/${idx.name}`}>  <img src={`https://ntbackend.uz/Uploads/${idx.photo}`} /></Link>
                                            
                                            
                                        </div>
                                        <div className="card-title">
                                            <Link to={`/app/info/${idx.name}`} className="h5">{idx.name}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={`https://ntbackend.uz/Uploads/${idx.photo}`} />
                                                </div>
                                               
                                            </div>
                                            <Link to='#' className="wishlist-button heart">
                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.75 4.3125C11.75 2.86292 10.5256 1.6875 9.01533 1.6875C7.88658 1.6875 6.91708 2.34433 6.5 3.28175C6.08292 2.34433 5.11342 1.6875 3.98408 1.6875C2.475 1.6875 1.25 2.86292 1.25 4.3125C1.25 8.52417 6.5 11.3125 6.5 11.3125C6.5 11.3125 11.75 8.52417 11.75 4.3125Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    
                                                <span className="number-like"> 3432</span></Link>
                                        </div>
                                        <div className="card-bottom style-explode">
                                           
                                            <div className="button-place-bid" style={{width:"100%"}}>
                                                <Link style={{textAlign:"center", width:"100%"}} to={`/app/info/${idx.name}`} data-toggle="modal" data-target="#popup_bid" className="sc-button"><span>{language === 'ru' ? "Подробно" : "Batafsil"}</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>
            </section>
    );
}

export default Project3;