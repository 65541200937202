import React from 'react';
import dataBox from '../assets/fake-data/data-box';
import dataFaqs from '../assets/fake-data/data-faq';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import dataProject from '../assets/fake-data/dataProject';
import dataTeam from '../assets/fake-data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';
import Project from '../components/project/Project';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';
import Testimonials2 from '../components/testimonials/Testimonials2';
import dataTestimonials2 from '../assets/fake-data/data-testimonials2';
import Counter from '../components/counter/Counter';
import { Helmet } from "react-helmet";
import img from '../components/android-chrome-512x512.png'

function Home01(props) {

  return (

    <div className="home-1 wrapper">
      <Helmet>
        <title>Uzbekcha Tikish Platforma - Yuklabet, 1xbet, Melbet Yuklash</title>
        <meta
          name="description"
          content="Eng yaxshi uzbekcha tikish platformalari: yuklabet, 1xbet, 888 starz, melbet yuklab oling va sportga tikishni boshlang."
        />
        <meta
          name="keywords"
          content="yuklabet, 1xbet yuklash, 888 starz yuklash, melbet yuklash, uzbekcha tikish platforma, sportga tikish Uzbekistan, onlayn kazino, tikish ilovalari, uzbekcha 1xbet, 888 starz ilova, tezkor tikish platformasi, mobil tikish dasturlari"
        />
        <meta property="og:title" content="Uzbekcha Tikish Platforma" />
        <meta property="og:description" content="Eng yaxshi uzbekcha tikish platformalari va dasturlarini yuklab oling va sportga tikishni boshlang." />
        <meta property="og:image" content={img} />
        <meta property="og:url" content="https://https888starz-uz.com/" />
        <meta name="image" content={img} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Uzbekcha Tikish Platforma - Yuklabet, 1xbet, Melbet Yuklash" />
        <meta name="twitter:description" content="Eng yaxshi uzbekcha tikish platformalari va dasturlarini yuklab oling." />
        <meta name="twitter:image" content={img} />

      </Helmet>
      <Banner />

      <About />




      <Roadmap data={dataRoadMap} />

      <Testimonials2 data={dataTestimonials2} />
      <Counter />


      <Faqs data={dataFaqs} />


      <Footer />
    </div>


  );
}

export default Home01;