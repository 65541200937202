import React, { useState } from 'react';

import img1 from '../../assets/images/logo/1xBet.png'
import img2 from '../../assets/images/logo/299ec2e753e9f9d99a978dd885de2a4d.jpg'
import img3 from '../../assets/images/logo/melbet-logo.jpg'
import img4 from '../../assets/images/logo/22bet-image.png'
import img5 from '../../assets/images/logo/888.webp'
import Button from '../button/Button';
import useLanguage from '../location';



function About(props) {
    const language = useLanguage();

    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        {
            id: 2,
            img: img2,
            class: 'img2'
        },
        {
            id: 3,
            img: img3,
            class: 'img3'
        },
        {
            id: 4,
            img: img4,
            class: 'img4'
        },
        {
            id: 5,
            img: img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState([
        {
            ru: {
                subheading: 'коротко о наших приложениях',
                heading: 'Максимум возможностей',
                desc1: 'Наши приложения предлагают широкий выбор для скачивания в таких категориях, как ставки, казино, спорт и киберспортивные игры. Мы предоставляем лучшие программы для ставок на спортивные события, увлекательные игры в казино, а также приложения для ставок на популярные киберспортивные турниры.',
                desc2: 'Все наши приложения разработаны для удобства и максимального комфорта пользователей, предоставляя доступ к лучшим возможностям для азартных игр и спортивных ставок, которые всегда под рукой.'
            },
            uz: {
                subheading: 'bizning ilovalarimiz haqida qisqacha',
                heading: 'Imkoniyatlarning maksimal darajasi',
                desc1: 'Bizning ilovalarimiz, stavkalar, kazino, sport va kiber-sport o\'yinlari kabi turli kategoriyalar bo\'yicha yuklab olish uchun keng tanlovni taklif etadi. Biz sport voqealariga stavkalar qilish, kazino o\'yinlaridan bahramand bo\'lish, shuningdek, mashhur kiber-sport turnirlariga stavkalar qilish uchun eng yaxshi dasturlarni taqdim etamiz.',
                desc2: 'Barcha ilovalarimiz foydalanuvchilar uchun qulaylik va maksimal darajadagi komfortni ta\'minlash maqsadida ishlab chiqilgan bo\'lib, ular har doim qo\'l ostida bo\'lgan eng yaxshi imkoniyatlarga kirish imkonini beradi.'
            }
        }
    ]);

    return (
        <section className="about">
            <div className="shape"></div>
            <div className="container">
                <div className="row rev">
                    <div className="col-xl-6 col-md-12">
                        <div className="about__right">
                            <div className="images">
                                {
                                    dataImg.map(idx => (
                                        <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        {dataBlock.map((block, index) => (
                            <div key={index}  className="block-text" >
                                <h6 className="sub-heading"><span>{language === 'ru' ? block.ru.subheading : block.uz.subheading}</span></h6>
                                <h3 className="heading">{language === 'ru' ? block.ru.heading : block.uz.heading}</h3>
                                <p className="mb-17">{language === 'ru' ? block.ru.desc1 : block.uz.desc1}</p>
                                <p className="mb-26">{language === 'ru' ? block.ru.desc2 : block.uz.desc2}</p>
                                <Button link='/apps' title={language === 'ru' ? "Иследуйте приложения" : "Ilovalarni korish"} />
                            </div>
                        ))}
                      
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;