

const dataRoadMap = [{
    uz: [
        {
            id: 1,
            time: 'February 01, 2022',
            title: 'Ilovani Topish',
            desc: "Bizning saytimizga kiring va ilovalar bo‘limini tanlang .Saytda mavjud bo‘lgan 1xbet yoki Mostbet ilovalaridan birini tanlash uchun tavsif va reytinglarni ko‘rib chiqing.",
            class: ''
        },
        {
            id: 2,
            time: 'February 01, 2022',
            title: ' Ilovani Yuklab Olish',
            desc: 'Tanlangan ilovaning yuklab olish tugmasini bosing. Qurilmangizga mos versiyani yuklab olish uchun ko‘rsatmalarni bajaring.',
            class: 'right'
        },

        {
            id: 3,
            time: 'February 01, 2022',
            title: 'Promokodni Kiritish',
            desc: 'Ro‘yxatdan o‘tish yoki birinchi marta depozit kiritishda maxsus promokodni kiriting. Promokodni kiritish orqali qo‘shimcha bonuslarga ega bo‘ling',
            class: ''
        },

        {
            id: 4,
            time: 'February 01, 2022',
            title: 'Depozit Qo‘yish',
            desc: 'Saytimizdagi tanlangan ilovaga kirib, hisobingizni to‘ldirish uchun mavjud to‘lov usullaridan birini tanlang.',
            class: 'right'
        },
        {
            id: 5,
            time: 'February 01, 2022',
            title: 'Yutuqlarni Olish',
            desc: ' Yutug‘ingizni olmoqchi bo‘lsangiz, ilovaning to‘lov bo‘limiga o‘ting va pulni chiqarish uchun kerakli ko‘rsatmalarni bajaring. Hisobingizdan yutuqni chiqarish uchun birinchi navbatda to‘lov usulini tanlab, so‘ngra mablag‘ni tasdiqlang.',
            class: ''
        },

    ],
    ru: [
        {
            id: 1,
            time: 'February 01, 2022',
            title: 'Найти приложение',
            desc: "Перейдите на наш сайт и выберите раздел с приложениями. Ознакомьтесь с описанием и рейтингами доступных приложений 1xbet или Mostbet, чтобы выбрать подходящее.",
            class: ''
        },
        {
            id: 2,
            time: 'February 01, 2022',
            title: 'Скачать приложение',
            desc: 'Нажмите кнопку для скачивания выбранного приложения. Следуйте инструкциям для загрузки версии, подходящей для вашего устройства.',
            class: 'right'
        },
        {
            id: 3,
            time: 'February 01, 2022',
            title: 'Введите промокод',
            desc: 'При регистрации или первом пополнении счета введите специальный промокод. Ввод промокода дает вам дополнительные бонусы.',
            class: ''
        },
        {
            id: 4,
            time: 'February 01, 2022',
            title: 'Внесите депозит',
            desc: 'Перейдите в выбранное приложение на нашем сайте и выберите один из доступных методов пополнения счета.',
            class: 'right'
        },
        {
            id: 5,
            time: 'February 01, 2022',
            title: 'Получение выигрыша',
            desc: 'Если вы хотите вывести свой выигрыш, перейдите в раздел выплат в приложении и следуйте инструкциям для вывода средств. Для вывода выигрыша сначала выберите метод оплаты, а затем подтвердите сумму.',
            class: ''
        },
    ]
}




]

export default dataRoadMap;