
import img1 from '../images/layouts/banner-01.png'
import img2 from '../images/layouts/banner-02.png'
import img3 from '../images/layouts/banner-03.png'

import avt from '../images/layouts/avt-05.png'



const dataCard = [

    {
        id: 1,
        img: img1,
        title: '888starz',
        price : '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 2,
        img: img2,
        title: '888starz',
        price : '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 3,
        img: img3,
        title: '888starz ',
        price : '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },


]

export default dataCard;