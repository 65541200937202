const dataFaqs = [{
    uz: [
          {
        id: 1,
        title: 'Dasturlar xavfsizmi?',
        text: 'Ha, bizning veb-saytimizdagi barcha dasturlar xavfsiz va tekshirilgan. Ushbu dasturlar sizning shaxsiy maʼlumotlaringiz xavfsizligini taʼminlaydi.',
        show: ''
    },
    {
        id: 2,
        title: 'Dasturni qanday yuklab olishim mumkin?',
        text: 'Saytimizda kerakli dastur nomini tanlang va yuklab olish tugmasini bosing. Yuklab olish tez va oson amalga oshiriladi.',
        show: ''
    },
    {
        id: 3,
        title: 'Dasturda qanday funksiyalar mavjud?',
        text: 'Har bir dastur o‘yinlarni o‘ynash, hisobni boshqarish va bonuslar olish kabi imkoniyatlarni taqdim etadi. Tafsilotlar uchun iltimos dastur haqida batafsil o‘qing.',
        show: ''
    },
    {
        id: 4,
        title: 'Dasturni yangilash kerakmi?',
        text: 'Ha, dasturning to‘g‘ri ishlashi va xavfsizligi uchun yangilanishlarni kuzatib boring va yangilab turing.',
        show: ''
    },
    {
        id: 5,
        title: 'Dasturni o‘chirish jarayoni qanday amalga oshiriladi?',
        text: 'Dasturni o‘chirish uchun mobil qurilmangizning sozlamalaridan kerakli dasturni tanlang va o‘chirish tugmasini bosing.',
        show: ''
    },
    {
        id: 6,
        title: 'Agar muammolar yuzaga kelsa, kimga murojaat qilish kerak?',
        text: 'Har qanday muammolar yuzasidan bizning texnik yordam xizmatimiz bilan bog‘lanishingiz mumkin.',
        show: ''
    },
    {
        id: 7,
        title: 'Yuklab olish vaqtida muammo yuzaga keldi. Nima qilishim kerak?',
        text: 'Iltimos, internet aloqangizni tekshiring va qayta urinib ko‘ring yoki texnik yordamga murojaat qiling.',
        show: ''
    },
    {
        id: 8,
        title: 'Dasturni o‘rnatganimda hisob yaratish kerakmi?',
        text: 'Yoq, bizni saytimizda tez va oson royhatdan masdan iloa yuklashingiz mumkin',
        show: ''
    }
    ],
    ru: [
        {
            id: 1,
            title: 'Безопасны ли приложения?',
            text: 'Да, все приложения на нашем веб-сайте безопасны и проверены. Эти приложения обеспечивают безопасность ваших личных данных.',
            show: ''
        },
        {
            id: 2,
            title: 'Как я могу скачать приложение?',
            text: 'На нашем сайте выберите нужное приложение и нажмите кнопку загрузки. Скачивание выполняется быстро и просто.',
            show: ''
        },
        {
            id: 3,
            title: 'Какие функции доступны в приложении?',
            text: 'Каждое приложение предоставляет возможности для игры, управления счетом и получения бонусов. Пожалуйста, прочтите подробности о приложении.',
            show: ''
        },
        {
            id: 4,
            title: 'Нужно ли обновлять приложение?',
            text: 'Да, следите за обновлениями и обновляйте приложение для его корректной работы и безопасности.',
            show: ''
        },
        {
            id: 5,
            title: 'Как удалить приложение?',
            text: 'Чтобы удалить приложение, выберите его в настройках мобильного устройства и нажмите кнопку удаления.',
            show: ''
        },
        {
            id: 6,
            title: 'К кому обратиться при возникновении проблем?',
            text: 'По любым вопросам вы можете обратиться в нашу службу технической поддержки.',
            show: ''
        },
        {
            id: 7,
            title: 'Возникла проблема при загрузке. Что делать?',
            text: 'Проверьте соединение с интернетом и попробуйте снова или обратитесь в техническую поддержку.',
            show: ''
        },
        {
            id: 8,
            title: 'Нужно ли создавать аккаунт при установке приложения?',
            text: 'Нет, в большинстве приложений не требуется создание аккаунта для доступа к персональным возможностям и бонусам.',
            show: ''
        }
    ]
    
}
  
];

export default dataFaqs;
