import React , {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

import img from '../../assets/images/background/line.png'
import useLanguage from '../location';

Roadmap.propTypes = {
    data: PropTypes.array
};

function Roadmap({data}) {
    const language = useLanguage();
    const [dataBlock] = useState({
      uz:  {
            subheading: 'Qollanma',
            heading: '1xbet va 888 Starz Garov Tikish va Yutish Strategiyalari',
        } ,
        ru: {
            subheading: 'Инстурция',
            heading: 'Стратегии ставок и выигрыша на 1xbet и 888Starz',
        }
    
       }
    )


    return (
        <section className="roadmap">
                <img src={img} alt="" className="img-line" />
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>{dataBlock[language].subheading}</span></h6>
                                <h3 className="heading pd">{dataBlock[language].heading}</h3>
                            </div>

                            <div className="roadmap__main" data-aos="fade-up" data-aos-duration="2000">

                           
 {data.map((idx) =>
                (language === 'uz' ? idx.uz : idx.ru).map((item) => (
                    
                  
                     <div key={item.id} className={`roadmap-box ${item.class}`} >
                     <div className="time">{item.time}</div>
                     <div className="content">
                         <h5 className="title">{item.title}</h5>
                         <p className="text">{item.desc}</p>
                     </div>
                 </div>
              ))
            )}

                                <div className="icon"></div>
                                <div className="icon bottom"></div>
                            </div>
                            <div className="button">
                                <Button title={language === 'ru' ? "Подробно" : "Qoshimcha"}  link='/' />
                            </div>

                        </div>

                    </div>
                </div>
            </section>

    );
}

export default Roadmap;