import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/logo/Generic-4-min.jpg.5b97b3b36a118078d790.webp'
import img2 from '../../assets/images/icon/icon-01.png'
import img3 from '../../assets/images/layouts/avt-01.png'
import useLanguage from '../location';
import win1 from "../../assets/images/logo/1xBet.png"
import starz from '../../assets/images/logo/888.webp'
function Banner(props) {
    const language = useLanguage();
    return (
        <section className="banner">

            <div className="shape right"></div>
            <div className="container big">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__left">
                            <div className="block-text">
                                <h2 className="heading">
                                    {language === 'ru' ? (
                                        <>
                                            Лучший <br />
                                            помощник <span className="s1 arlo_tm_animation_text_word">ставок</span> <br />
                                            в мире
                                        </>
                                    ) : (
                                        <>
                                            Online  qimor <br />
                                            <br />
                                            dunyosiga               <span className="s1 arlo_tm_animation_text_word"> xush kelibsiz </span>
                                        </>
                                    )}
                                </h2>
                                <p className="desc"></p>

                                <Link to="/apps" className="action-btn"> {language === 'ru' ? <span>Скачайти приложении</span> : <span>Ilovalarni korish</span>} </Link>
                            </div>

                            <div className="pay">
                                <h6>We are Monteno NFT</h6>

                                <div className="list">
                                    <p>We accept:</p>

                                    <ul>
                                        <li><Link to="#"><span className="icon-logo-01"></span></Link></li>
                                        <li><Link to="#"><span className="icon-logo-02"></span></Link></li>
                                        <li><Link to="#"><span className="icon-logo-03"></span></Link></li>
                                        <li><Link to="#"><span className="icon-logo-04"></span></Link></li>
                                        <li><Link to="#"><span className="icon-logo-05"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></Link></li>
                                        <li><Link to="#"><span className="icon-logo-06"></span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">

                        <div className="banner__right">
                            <div className="image">
                                <img src={img1} alt="yuklabet banner" />
                            </div>

                            <div className="price">
                                <div className="icon">
                                    <img style={{ width: "90px", borderRadius: "50%", height: "90px" }} src={win1} alt="cyfonii" />
                                </div>
                                <div className="content">
                                    <p>1xBet</p>
                                    <h5>1xBet</h5>
                                </div>
                            </div>

                            <div className="owner">
                                <div className="image">
                                    <img src={starz} alt="" />
                                </div>
                                <div className="content">
                                    <h5>888starz</h5>
                                    <p>casino</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;