
import img1 from '../images/logo/888.webp'
import img2 from '../images/logo/1xBet.png'
import img3 from '../images/logo/d11c8b92befa9f6636faa28cefa8ee6f.jpg'
import img4 from '../images/logo/Mostbet_desktop.webp'
import img5 from '../images/layouts/project-05.png'

const dataProject = [

    {
        id: 1,
        img: img1,
        title: '888starz'
    },
    {
        id: 2,
        img: img2,
        title: '1x Bet'
    },
    {
        id: 3,
        img: img3,
        title: '1win'
    },
    {
        id: 4,
        img: img4,
        title: 'Mostbet'
    },
    {
        id: 5,
        img: img5,
        title: 'The Strange Art'
    },
    {
        id: 6,
        img: img1,
        title: '3D Digital Artwork'
    },
    {
        id: 7,
        img: img2,
        title: 'King Of Pirates'
    },
    {
        id: 8,
        img: img3,
        title: 'Pomeranian Doge'
    },
    {
        id: 9,
        img: img4,
        title: 'Nintendo Switch'
    },


    

]

export default dataProject;