import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import icon1 from '../../assets/images/icon/quote-2.png'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import avt1 from '../../assets/images/layouts/avt-02.png';
import useLanguage from '../location';

Testimonials2.propTypes = {
    data : PropTypes.array
};


function Testimonials2({ data }) {
   
    const language = useLanguage();


   

    return (
        <section className="testimonials s2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="testimonials__main">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>{language === 'ru' ? "Мнение людей о нашей платформе" : "Bizhamiqda Fikrlar"} </span></h6>
                                <h3 className='heading'>
        {language === 'ru'
          ? (
            <>
              Комментарии людей  <br />о нашем платформе
            </>
          )
          : (
            <>
              Odamlarni bizni <br /> platformamiz haqidagi Izohlar
            </>
          )}
      </h3>
                            </div>

                            <Swiper
               
                            spaceBetween={30}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    },
                                768: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                            }}
                            className="testimonials-swiper s2"
                            loop= {true}
                            pagination
                        >
                                       
                    {
    data.map((idx) => (
        (language === 'uz' ? idx.uz : idx.ru).map((item) => (
            <SwiperSlide key={item.id}>
            <div className="box-testimonial center">
                    <div className="image">
                        <img src={avt1} alt="Cyfonii" />
                    </div>
                    
                    <div className="info">
                        <h5 className="name">{item.name}</h5>
                        <p>{item.position}</p>
                        <img src={icon1} alt="Cyfonii" />
                    </div>
                    <p className="text">{item.text}</p>
                </div>
        </SwiperSlide>
        ))
    ))
}
                    </Swiper>
    

                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials2;