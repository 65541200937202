import icon1 from '../images/icon/quote-2.png';
import avt1 from '../images/layouts/avt-08.png';
import avt2 from '../images/layouts/avt-09.png';
import avt3 from '../images/layouts/avt-10.png';

const dataTestimonials2 = [
    {
        uz: [   
            {
                id: 1,
                text: '“ Men ushbu platforma orqali dasturni oson va tez yuklab oldim. Hamma narsa tushunarli va xavfsiz ekanligini his qildim. Ajoyib xizmat! “',
                name: 'Anora Bekova',
                position: 'Foydalanuvchi'
            },
            {
                id: 2,
                text: '“ Ushbu sayt menga do‘stlarim tavsiya qilgan edi. Juda ko‘p foydali dasturlar mavjud. Yuklab olish jarayoni juda oddiy va tushunarli. Rahmat! “',
                name: 'Laziz Aliyev',
                position: 'Sodiq Mijoz'
            },
            {
                id: 3,
                text: '“ Platformadagi dasturlarning sifati yuqori va xavfsizlik kafolatlangan. Mijozlarga xizmat ko‘rsatish ham juda yordam beradi. Ushbu xizmatni hammaga tavsiya qilaman! “',
                name: 'Oydin Qodirova',
                position: 'Asosiy Foydalanuvchi'
            },
        ],
        ru: [
            {
                id: 1,
                text: '“ Я легко и быстро скачал программу через эту платформу. Я почувствовал, что все понятно и безопасно. Отличный сервис! “',
                name: 'Анна Бекова',
                position: 'Пользователь'
            },
            {
                id: 2,
                text: '“ Этот сайт рекомендовали мне мои друзья. Здесь много полезных программ. Процесс загрузки очень простой и понятный. Спасибо! “',
                name: 'Лазиз Алиев',
                position: 'Верный Клиент'
            },
            {
                id: 3,
                text: '“ Качество программ на платформе высокое, и безопасность гарантирована. Обслуживание клиентов тоже очень помогает. Я рекомендую эту услугу всем! “',
                name: 'Ойдин Кодирова',
                position: 'Основной Пользователь'
            },
        ],
    }
];

export default dataTestimonials2;
