
import { React, useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';

import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import { HelmetProvider } from 'react-helmet-async';
import { LanguageProvider } from './pages/Language';
import GoogleAnalytics from './components/GoogleAnalytics';

function App() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <LanguageProvider>
            <HelmetProvider>


                <GoogleAnalytics />
                <Header />

                <Routes>

                    {
                        routes.map((data, idx) => (
                            <Route key={idx} path={data.path} element={data.component} exact />
                        ))
                    }

                    <Route path='*' element={<Page404 />} />
                </Routes>

            </HelmetProvider>
        </LanguageProvider>
    );
}

export default App;
